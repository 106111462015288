// external dependencies
import React from 'react';

// gatsby dependencies
import { graphql, useStaticQuery } from 'gatsby';

// internal dependencies
import SEO from '../components/SEO';
import Hero from '../components/shared/Hero';
import Cta from '../components/shared/Cta';

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "fence-placeholder.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `);
  return (
    <>
      <SEO title='404: Page Not found' />
      <Hero
        headline='Page Not Found'
        buttonLink='/'
        buttonText='back to home'
      />
      <Cta
        ctaSupertext='FREE CONSULTATION'
        ctaHeadline='Call now to speak with one of our professional engineers.'
        phoneNumber='8009429255'
        email='sales@concretefence.com'
        ctaImage={data.placeholderImage.gatsbyImageData}
        altText=''
      />
    </>
  );
};

export default NotFoundPage;
